import CryptoJS from 'crypto-js';
import { uaCheck } from 'ua-check'

const ua = uaCheck()
// 加密函数
export const encryptAES = (data) => {
  const encrypted = CryptoJS.AES.encrypt(data, 'diHJIOU6xRbIOE0uvlYMtRzinCunFLnSIf55eciI4ENCj68mvf4UHTn5iuqJDC3k', {});
  return encrypted.toString();
}
// const mobileRE = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
const mobileRE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
const screenWidth = localStorage.getItem('ScreenWidth')
export const isMobile = () => {
  let result = mobileRE.test(navigator.userAgent)
  if (
    !result &&
        navigator &&
        navigator.maxTouchPoints > 1 &&
        navigator.userAgent.indexOf('Macintosh') !== -1 &&
        navigator.userAgent.indexOf('Safari') !== -1
  ) {
    result = true
  }else if(result && screenWidth > 999){
    result = false
  }
  return result
}

export const deviceType = () => {
  if (ua.isAndroid && ua.isPhone) {
    if (ua.isWeChatBrowser) {
      return 'wechat'
    } else {
      return 'android'
    }
  } else if (ua.isIOS && !ua.isPc) {
    if (ua.isWeChatBrowser) {
      return 'wechat'
    }
    return 'ios'
  } else if (ua.isPc) {
    return 'pc'
  }
  return 'pc'
}

// 获取assets静态资源
export  const getAssetsFile = (url) => {
  return new URL(`../assets/images/${url}`, import.meta.url).href
}

export  const getWindowConfig = () => {
  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  if (typeof windowWidth !== 'number') {
    if (document.compatMode === 'CSS1Compat') {
      windowWidth = document.documentElement.clientWidth;
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowWidth = document.body.clientWidth;
      windowHeight = window.body.clientHeight;
    }
  }
  return {
    windowWidth,
    windowHeight
  }
}

// 设计稿以1920px为宽度，而我把页面宽度设计为10rem的情况下
import {isMobile} from './index'
const pageWidth = isMobile() ? 750 : 2560;
const baseSize = pageWidth / 10  // 这个是设计稿中1rem的大小。

export function setRem() {
    // 实际设备页面宽度和设计稿的比值
    const scale = document.documentElement.clientWidth / pageWidth;
    // 计算实际的rem值并赋予给html的font-size
    document.documentElement.style.fontSize = (baseSize * scale) + 'px';
}

export function resetRem() {
    document.documentElement.style.fontSize = baseSize + 'px';
}
